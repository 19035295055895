import React, { useState, useEffect } from 'react';
import { Star, Award, Globe2, Users, Mic, BookOpen, MapPin, Rocket, GraduationCap, Brain, Library, BookMarked } from 'lucide-react';
import Navigation from '../components/Navigation';
import FAQ from '../FAQ';
import WorldMap from '../WorldMap';
import Sitemap from '../components/Sitemap';

function Home() {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  
  const quotes = [
    "El verdadero líder no es quien manda, sino quien inspira a otros a encontrar su propio camino.",
    "No busques seguidores; busca personas que también quieran convertirse en guías.",
    "Emprender no es para los que tienen ideas perfectas, sino para los que tienen el coraje de actuar con lo que tienen.",
    "El éxito no llega cuando todo está listo; llega cuando decides avanzar aunque nada esté claro.",
    "La excusa más grande del mundo es 'no tengo tiempo'. El tiempo existe, lo que falta es prioridad.",
    "Crecer duele, pero quedarse igual duele más. Decide qué dolor prefieres enfrentar.",
    "Las mejores relaciones no son las que te hacen sentir cómodo, sino las que te desafían a ser mejor.",
    "Quien te apoya en tus peores momentos es quien realmente merece estar en tus mejores días.",
    "No pierdas tu energía tratando de convencer a quienes no creen en ti. Dedica ese tiempo a quienes ya lo hacen.",
    "El éxito no es un destino, es una forma de vivir cada día con propósito y disciplina.",
    "No puedes controlar el viento, pero siempre puedes ajustar las velas. La actitud lo es todo.",
    "El fracaso no te define; lo que haces después de caer es lo que cuenta.",
    "La vida no espera a que estés listo; toma acción hoy porque mañana podría ser tarde.",
    "No gastes tu vida persiguiendo aplausos; trabaja en silencio y deja que tus resultados hablen por ti.",
    "El dinero no compra la felicidad, pero la libertad financiera te da tiempo para buscarla donde realmente está: dentro de ti."
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [quotes.length]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white">
      <Navigation />
      
      {/* Hero Section */}
      <header className="relative h-[80vh] flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1475721027785-f74eccf877e2?auto=format&fit=crop&q=80"
            alt="Omar Villalobos Speaking" 
            className="w-full h-full object-cover opacity-25"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-80"></div>
        </div>
        <div className="relative z-10 container mx-auto px-6 text-center">
          <h1 className="text-6xl md:text-8xl font-extrabold mb-6 tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-white via-red-500 to-white">
            OMAR VILLALOBOS
          </h1>
          <p className="text-2xl md:text-3xl font-light tracking-wider uppercase text-gray-300 mb-12">
            Maestro de la Transformación
          </p>
          
          {/* Quote Slider */}
          <div className="max-w-3xl mx-auto relative h-32 md:h-24">
            {quotes.map((quote, index) => (
              <div 
                key={index}
                className={`absolute inset-0 transition-opacity duration-1000 flex items-center justify-center ${
                  index === currentQuoteIndex ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <p className="text-xl md:text-2xl italic text-gray-200 leading-relaxed">
                  "{quote}"
                </p>
              </div>
            ))}
          </div>
          
          <div className="flex justify-center mt-8">
            {quotes.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentQuoteIndex(index)}
                className={`w-2 h-2 mx-1 rounded-full ${
                  index === currentQuoteIndex ? 'bg-red-500' : 'bg-gray-500'
                }`}
                aria-label={`Ver frase ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </header>

      {/* Introduction Section */}
      <section className="py-24">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto text-center">
            <Star className="w-16 h-16 text-red-500 mx-auto mb-12 animate-pulse" />
            <p className="text-2xl leading-relaxed text-gray-300 mb-8 font-light">
              Con más de <span className="text-red-500 font-semibold">30 años de trayectoria</span> comprobada como speaker profesional, Omar Villalobos se ha consolidado como una figura icónica en el ámbito del desarrollo humano y la oratoria motivacional. Su estilo único, que combina profundidad emocional, humor y enseñanzas prácticas, lo ha convertido en un referente obligado para quienes buscan inspiración y transformación personal.
            </p>
          </div>
        </div>
      </section>

      {/* World Map Section */}
      <WorldMap />

      {/* Origins Section */}
      <section className="py-24 bg-black/50">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center justify-center mb-16">
              <MapPin className="w-12 h-12 text-red-500 mr-4" />
              <h2 className="text-4xl font-bold bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                Origen y Primeros Años
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <MapPin className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Raíces</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Omar Villalobos nació en Chihuahua, México, en 1976. Desde joven, mostró un gran interés por el aprendizaje y el desarrollo personal, características que definirían su futuro camino.
                </p>
              </div>
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <Rocket className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Primeros Sueños</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  En sus primeros años, Omar soñaba con ser piloto aviador. Aunque no logró ingresar a la academia debido a su altura en aquella época, esta experiencia temprana marcó el inicio de su búsqueda por superar obstáculos y alcanzar metas ambiciosas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Studies Section */}
      <section className="py-24 bg-gradient-to-b from-red-900/20 to-transparent">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center justify-center mb-16">
              <GraduationCap className="w-12 h-12 text-red-500 mr-4" />
              <h2 className="text-4xl font-bold bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                Estudios y Preparación
              </h2>
            </div>
            
            <div className="space-y-12">
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <Library className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Formación Académica</h3>
                </div>
                <div className="space-y-6 text-gray-300 text-lg leading-relaxed">
                  <p>
                    Su educación formal incluye estudios en prestigiosas instituciones:
                  </p>
                  <ul className="space-y-4 list-disc list-inside pl-4">
                    <li>Maestría en Terapia Gestalt en INTEGRO, Guadalajara</li>
                    <li>Especialidad en Terapia Racional Emotiva en el Instituto Albert Ellis, Nueva York</li>
                    <li>Programación Neurolingüística (PNL) con Richard Bandler en Miami</li>
                    <li>FireWalk Certified con Tolly Burkan</li>
                    <li>Certificación en Negociación y Persuasión de Harvard</li>
                    <li>Universidad de Texas en El Paso (UTEP)</li>
                    <li>Instituto Teresiano de Psicología en Ciudad Juárez</li>
                    <li>Tecnológico de Monterrey</li>
                  </ul>
                </div>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <Brain className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Filosofía de Aprendizaje</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed mb-6">
                  Omar se define como un autodidacta apasionado y un eterno aprendiz. Su filosofía trasciende los títulos académicos: cree firmemente que el conocimiento auténtico y la experiencia no necesitan ser anunciados; simplemente se notan en la práctica y los resultados.
                </p>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Su capacidad extraordinaria para absorber, comprender y aplicar conocimientos, junto con su habilidad única de conectar disciplinas y transformar la teoría en acción, lo distinguen como un verdadero maestro en su campo.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <Star className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Certificaciones Especializadas</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed mb-6">
                  Su búsqueda constante de excelencia lo ha llevado a obtener certificaciones internacionales en diversas áreas:
                </p>
                <ul className="space-y-4 text-gray-300 list-disc list-inside pl-4">
                  <li>Certificación en PNL con Richard Bandler, co-creador de la PNL</li>
                  <li>Certificación en FireWalk con Tolly Burkan, pionero en caminata sobre fuego</li>
                  <li>Certificaciones en desarrollo humano y transformación personal</li>
                  <li>Programas especializados en liderazgo y comunicación efectiva</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Books Section */}
      <section className="py-24 bg-black/50">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center justify-center mb-16">
              <BookMarked className="w-12 h-12 text-red-500 mr-4" />
              <h2 className="text-4xl font-bold bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                Obras Literarias
              </h2>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur transform hover:scale-105 transition-all duration-300 border border-red-500/20">
                <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                  Ser Chingón Sin Ir a Harvard
                </h3>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Su libro más vendido en Latinoamérica. Una obra que revoluciona la perspectiva del éxito personal y profesional, demostrando que los títulos académicos tradicionales no son el único camino hacia el triunfo.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur transform hover:scale-105 transition-all duration-300 border border-red-500/20">
                <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                  Genius: La Psicología de la Genialidad
                </h3>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Fruto de años de investigación exhaustiva, este libro explora los secretos detrás de la genialidad y cómo las personas pueden desarrollar habilidades excepcionales.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur transform hover:scale-105 transition-all duration-300 border border-red-500/20">
                <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                  Ser un Triunfador Sin Ir a Harvard
                </h3>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Una obra que comparte estrategias prácticas para alcanzar el éxito en la vida personal y profesional, destacándose por su versatilidad y aplicabilidad en la vida cotidiana.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur transform hover:scale-105 transition-all duration-300 border border-red-500/20">
                <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                  Haz Posible Lo Imposible
                </h3>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Una obra inspiradora que refleja el enfoque de Omar hacia el logro de metas ambiciosas, demostrando que los límites solo existen en nuestra mente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Achievements Grid */}
      <section className="py-20 bg-black/50 backdrop-blur">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
            <div className="text-center transform hover:scale-105 transition-transform duration-300">
              <Globe2 className="w-16 h-16 mx-auto mb-6 text-red-500" />
              <h3 className="text-3xl font-bold mb-3">25+ Países</h3>
              <p className="text-gray-400 text-lg">Impactando vidas globalmente</p>
            </div>
            <div className="text-center transform hover:scale-105 transition-transform duration-300">
              <Users className="w-16 h-16 mx-auto mb-6 text-red-500" />
              <h3 className="text-3xl font-bold mb-3">Miles de Vidas</h3>
              <p className="text-gray-400 text-lg">Transformadas por sus enseñanzas</p>
            </div>
            <div className="text-center transform hover:scale-105 transition-transform duration-300">
              <Award className="w-16 h-16 mx-auto mb-6 text-red-500" />
              <h3 className="text-3xl font-bold mb-3">Referente</h3>
              <p className="text-gray-400 text-lg">En oratoria motivacional</p>
            </div>
          </div>
        </div>
      </section>

      {/* Main Biography Section */}
      <section className="py-24">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <div className="mb-20">
              <h2 className="text-4xl font-bold mb-12 flex items-center">
                <Mic className="w-12 h-12 text-red-500 mr-6" />
                <span className="bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                  El Rock Star de los Oradores
                </span>
              </h2>
              <p className="text-xl leading-relaxed text-gray-300 mb-8">
                Omar Villalobos no solo es un conferencista, sino un verdadero "rock star" de los oradores. Su carisma, energía en el escenario y capacidad para conectar con su público lo han llevado a ser comparado con las grandes figuras del entretenimiento, pero con un propósito mucho más profundo: transformar vidas.
              </p>
              <p className="text-xl leading-relaxed text-gray-300">
                Desde sus inicios, ha sabido reinventarse y adaptarse a los cambios de la sociedad, lo que lo mantiene vigente y relevante en un mundo en constante evolución.
              </p>
            </div>

            <div>
              <h2 className="text-4xl font-bold mb-12 flex items-center">
                <BookOpen className="w-12 h-12 text-red-500 mr-6" />
                <span className="bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                  Legado e Impacto
                </span>
              </h2>
              <p className="text-xl leading-relaxed text-gray-300 mb-6">
                Reconocido como uno de los oradores más influyentes de Latinoamérica, su impacto trasciende fronteras, llegando a audiencias en más de 25 países y dejando una huella imborrable en miles de personas. Es, sin duda, uno de los profesionales más copiados y modelados en la industria, estableciendo un estándar al que muchos aspiran pero pocos logran igualar.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <FAQ />

      {/* Footer */}
      <footer className="bg-black py-8">
        <div className="container mx-auto px-6 text-center text-gray-400">
          <p className="text-lg">&copy; {new Date().getFullYear()} Omar Villalobos. Todos los derechos reservados.</p>
        </div>
      </footer>

      {/* Sitemap */}
      <Sitemap />
    </div>
  );
}

export default Home;