import React, { useState } from 'react';
import { MessageCircle, Send, X } from 'lucide-react';

function WhatsAppContact() {
  const [showModal, setShowModal] = useState(false);

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent('Me interesa saber más de los servicios de Omar Villalobos');
    window.open(`https://wa.me/523324166849?text=${message}`, '_blank');
  };

  return (
    <>
      {/* Floating Button */}
      <button
        onClick={() => setShowModal(true)}
        className="fixed bottom-24 right-6 z-40 bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded-full font-medium transition-all duration-300 flex items-center gap-2 shadow-lg hover:scale-105"
      >
        <MessageCircle className="w-5 h-5" />
        <span>Contactar Ahora</span>
      </button>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl max-w-md w-full overflow-hidden relative">
            {/* Close button */}
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>

            {/* WhatsApp branding header */}
            <div className="bg-green-500 p-6 text-white text-center">
              <MessageCircle className="w-16 h-16 mx-auto mb-4" />
              <h3 className="text-2xl font-bold">¡Dejemos las complicaciones!</h3>
              <p className="text-lg opacity-90">WhatsApp es lo de Hoy!</p>
            </div>

            {/* Content */}
            <div className="p-6">
              <div className="text-center mb-8">
                <p className="text-gray-600 text-lg">
                  Conecta directamente con nosotros a través de WhatsApp y recibe atención personalizada al instante.
                </p>
              </div>

              <button
                onClick={handleWhatsAppClick}
                className="w-full bg-green-500 hover:bg-green-600 text-white px-6 py-4 rounded-xl font-medium transition-colors flex items-center justify-center gap-3 text-lg"
              >
                <MessageCircle className="w-6 h-6" />
                <span>Abrir WhatsApp</span>
                <Send className="w-5 h-5" />
              </button>

              <p className="text-center text-sm text-gray-500 mt-4">
                Te responderemos lo más pronto posible
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WhatsAppContact;