import React, { useState, useEffect } from 'react';
import { Home, Award, BookOpen, Video } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

function Navigation() {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const isActive = (path: string) => currentPath === path;

  const links = [
    { path: '/', icon: Home, text: 'Inicio' },
    { path: '/biography', icon: BookOpen, text: 'Biografía' },
    { path: '/achievements', icon: Award, text: 'Logros' },
    { path: '/videos', icon: Video, text: 'Videos' }
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-black/80 backdrop-blur-md border-b border-red-500/20">
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-8">
            {links.map(({ path, icon: Icon, text }) => (
              <Link 
                key={path}
                to={path} 
                className={`flex items-center space-x-2 transition-colors ${
                  isActive(path) 
                    ? 'text-red-500' 
                    : 'text-white hover:text-red-400'
                }`}
              >
                <Icon className="w-5 h-5" />
                <span className="font-semibold">{text}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;