import React, { useState, useEffect } from 'react';
import { Home, Award, BookOpen, Video, Menu, X } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

function Navigation() {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setCurrentPath(location.pathname);
    // Close menu when route changes
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  }, [location]);

  const isActive = (path: string) => currentPath === path;

  const links = [
    { path: '/', icon: Home, text: 'Inicio' },
    { path: '/biography', icon: BookOpen, text: 'Biografía' },
    { path: '/achievements', icon: Award, text: 'Logros' },
    { path: '/videos', icon: Video, text: 'Videos' }
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'auto';
  };

  // Clean up the body style when component unmounts
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <nav className="fixed top-0 left-0 right-0 z-[100]">
      {/* Navigation Bar */}
      <div className="relative bg-black/90 backdrop-blur-md border-b border-red-500/20">
        <div className="container mx-auto px-6">
          <div className="flex items-center justify-between h-16">
            {/* Logo/Home Link */}
            <Link 
              to="/"
              className="text-white font-bold text-xl hover:text-red-500 transition-colors md:hidden"
            >
              OMV
            </Link>

            {/* Mobile Menu Button */}
            <button
              onClick={toggleMenu}
              className="md:hidden relative z-50 w-10 h-10 flex items-center justify-center rounded-lg bg-gray-800 text-white hover:bg-gray-700 transition-colors"
              aria-label="Toggle menu"
              aria-expanded={isMenuOpen}
            >
              {isMenuOpen ? (
                <X className="w-5 h-5 text-red-500" />
              ) : (
                <Menu className="w-5 h-5" />
              )}
            </button>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              {links.map(({ path, icon: Icon, text }) => (
                <Link 
                  key={path}
                  to={path} 
                  className={`flex items-center space-x-2 px-3 py-2 rounded-lg transition-all ${
                    isActive(path) 
                      ? 'text-red-500 bg-red-500/10' 
                      : 'text-white hover:text-red-400 hover:bg-gray-800'
                  }`}
                >
                  <Icon className="w-5 h-5" />
                  <span className="font-semibold">{text}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu - Fullscreen */}
      <div
        className={`fixed inset-0 bg-gradient-to-b from-gray-900 via-black to-gray-900 transform transition-all duration-300 ease-in-out md:hidden ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{ top: '64px' }}
      >
        <div className="flex flex-col h-[calc(100vh-64px)]">
          {/* Main Navigation Links */}
          <div className="flex-1 flex flex-col justify-center px-6 py-8 overflow-y-auto">
            {links.map(({ path, icon: Icon, text }) => (
              <Link
                key={path}
                to={path}
                onClick={toggleMenu}
                className={`flex items-center space-x-4 px-4 py-4 rounded-xl mb-4 transition-all ${
                  isActive(path)
                    ? 'text-red-500 bg-red-500/10 scale-105'
                    : 'text-white hover:text-red-400 hover:bg-gray-800/50'
                }`}
              >
                <Icon className={`w-6 h-6 ${isActive(path) ? 'animate-pulse' : ''}`} />
                <span className="text-xl font-medium">{text}</span>
              </Link>
            ))}
          </div>

          {/* Footer Content */}
          <div className="p-8 border-t border-gray-800">
            <div className="text-center">
              <h3 className="text-2xl font-bold bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent mb-2">
                Omar Villalobos
              </h3>
              <p className="text-gray-400">
                Maestro de la Transformación
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;