import React, { useRef } from 'react';
import { BookOpen, Star, MapPin, GraduationCap, Brain, Church, MessageCircle, Heart, ArrowLeftCircle, ArrowRightCircle, Book, Lightbulb, Smile as Family, Rocket, Briefcase, Target } from 'lucide-react';
import Navigation from './Navigation';
import Sitemap from './components/Sitemap';

function Biography() {
  const sliderRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (sliderRef.current) {
      const scrollAmount = direction === 'left' ? -400 : 400;
      sliderRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white">
      <Navigation />
      
      {/* Hero Section */}
      <header className="relative h-[60vh] flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&q=80"
            alt="Omar Villalobos Speaking" 
            className="w-full h-full object-cover opacity-25"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-80"></div>
        </div>
        <div className="relative z-10 container mx-auto px-6 text-center">
          <h1 className="text-6xl md:text-7xl font-extrabold mb-6 tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-white via-red-500 to-white">
            Biografía
          </h1>
          <div className="flex items-center justify-center">
            <BookOpen className="w-8 h-8 text-red-500 mr-3" />
            <p className="text-2xl font-light tracking-wider text-gray-300">
              La Historia de un Visionario
            </p>
          </div>
        </div>
      </header>

      {/* Personal Life Section */}
      <section className="py-24">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-16">
              <Family className="w-16 h-16 text-red-500 mx-auto mb-6" />
              <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                Vida Personal
              </h2>
              <p className="text-xl leading-relaxed text-gray-300">
                Omar Villalobos, nacido en Chihuahua, México, es un ejemplo vivo de cómo la 
                determinación y la pasión pueden transformar vidas. Padre de seis hijos, cada uno 
                nombrado en honor a figuras históricas y genios que han inspirado su vida:
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <h3 className="text-2xl font-bold mb-4">Sus Hijos</h3>
                <ul className="space-y-4 text-gray-300">
                  <li className="flex items-start gap-3">
                    <Heart className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-semibold">Juan Pablo:</span> Por Juan, el discípulo más joven, y Pablo, el mejor orador.
                    </div>
                  </li>
                  <li className="flex items-start gap-3">
                    <Heart className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-semibold">Sofía:</span> Por amor a la sabiduría.
                    </div>
                  </li>
                  <li className="flex items-start gap-3">
                    <Heart className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-semibold">Salomón:</span> Por el rey Salomón.
                    </div>
                  </li>
                  <li className="flex items-start gap-3">
                    <Heart className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-semibold">Dalai:</span> Por el Dalai Lama.
                    </div>
                  </li>
                  <li className="flex items-start gap-3">
                    <Heart className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-semibold">Dalí:</span> Por Salvador Dalí.
                    </div>
                  </li>
                  <li className="flex items-start gap-3">
                    <Heart className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-semibold">Zeus:</span> Porque se dio vida solo.
                    </div>
                  </li>
                </ul>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <h3 className="text-2xl font-bold mb-4">Pasiones y Hobbies</h3>
                <div className="space-y-4 text-gray-300">
                  <div className="flex items-start gap-3">
                    <Target className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <p>
                      <span className="font-semibold">Escalada en roca:</span> Su deporte favorito, 
                      que refleja su filosofía de vida de superar obstáculos y alcanzar nuevas alturas.
                    </p>
                  </div>
                  <div className="flex items-start gap-3">
                    <Book className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <p>
                      <span className="font-semibold">Lectura:</span> Ávido lector de filosofía, 
                      psicología y desarrollo personal.
                    </p>
                  </div>
                  <div className="flex items-start gap-3">
                    <Brain className="w-5 h-5 text-red-500 flex-shrink-0 mt-1" />
                    <p>
                      <span className="font-semibold">Aprendizaje continuo:</span> Dedicado al 
                      estudio constante y la investigación de nuevas técnicas de desarrollo humano.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Early Life Timeline */}
      <section className="py-24 bg-black/50">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
              Primeros Años
            </h2>

            <div className="space-y-8">
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <MapPin className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Orígenes en Chihuahua</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Nacido en Chihuahua, México, en 1976, Omar mostró desde temprana edad un interés 
                  innato por el aprendizaje y el desarrollo personal. Su ciudad natal sería el punto 
                  de partida para una extraordinaria jornada de transformación personal y profesional.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <Church className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Formación Espiritual</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Entre los 15 y 18 años, Omar se formó en comunidades religiosas con la aspiración 
                  de convertirse en sacerdote. Esta experiencia fundamental le proporcionó una profunda 
                  comprensión de la naturaleza humana y desarrolló sus habilidades de comunicación y 
                  conexión con las personas.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <Rocket className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Primeros Sueños</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  En su juventud, Omar soñaba con ser piloto aviador. Aunque no pudo ingresar a la 
                  academia debido a su altura, esta experiencia temprana le enseñó valiosas lecciones 
                  sobre la adaptabilidad y la importancia de encontrar caminos alternativos hacia el éxito.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Career Journey */}
      <section className="py-24">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
              Trayectoria Profesional
            </h2>

            <div className="space-y-8">
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <Briefcase className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Inicios como Orador</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Sus primeras experiencias como maestro en las comunidades religiosas sentaron las 
                  bases de lo que más tarde se convertiría en su distintivo estilo de enseñanza y 
                  motivación. Comenzó dando charlas pequeñas que gradualmente se transformaron en 
                  conferencias masivas.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <GraduationCap className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Formación Académica</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Comprometido con su desarrollo profesional, Omar ha estudiado en instituciones 
                  prestigiosas como la Universidad de Texas en El Paso (UTEP), el Instituto Teresiano 
                  de Psicología, y el Tecnológico de Monterrey, entre otros. Su filosofía de 
                  aprendizaje continuo lo ha llevado a obtener múltiples certificaciones y 
                  participar en programas especializados.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur">
                <div className="flex items-center mb-6">
                  <Brain className="w-8 h-8 text-red-500 mr-4" />
                  <h3 className="text-2xl font-bold">Filosofía de Vida</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Omar se define como un autodidacta apasionado y un eterno aprendiz. Su filosofía 
                  trasciende los títulos académicos: cree firmemente que el conocimiento auténtico 
                  y la experiencia no necesitan ser anunciados; simplemente se notan en la práctica 
                  y los resultados.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black py-8">
        <div className="container mx-auto px-6 text-center text-gray-400">
          <p className="text-lg">&copy; {new Date().getFullYear()} Omar Villalobos. Todos los derechos reservados.</p>
        </div>
      </footer>

      {/* Sitemap */}
      <Sitemap />
    </div>
  );
}

export default Biography;