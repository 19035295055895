import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Play, AlertCircle, Calendar, Eye, ThumbsUp, Share2, Clock, Video as VideoIcon, ExternalLink } from 'lucide-react';
import Navigation from '../Navigation';
import Sitemap from '../components/Sitemap';
import { getVideos, getVideoById } from '../lib/api';
import { Video } from '../types';

function Videos() {
  const [videos, setVideos] = useState<Video[]>([]);
  const [currentVideo, setCurrentVideo] = useState<Video | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [shareMessage, setShareMessage] = useState<string | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const result = await getVideos();
        
        if (!result.success) {
          throw new Error(result.error);
        }
        
        const fetchedVideos = result.data || [];
        setVideos(fetchedVideos);
        
        if (id) {
          const videoResult = await getVideoById(id);
          if (!videoResult.success) {
            throw new Error(videoResult.error);
          }
          setCurrentVideo(videoResult.data || null);
        } else if (fetchedVideos.length > 0) {
          setCurrentVideo(fetchedVideos[0]);
          navigate(`/videos/${fetchedVideos[0].id}`);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Error al cargar los videos');
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [id, navigate]);

  const handleVideoSelect = (video: Video) => {
    setCurrentVideo(video);
    navigate(`/videos/${video.id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('es-MX', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatNumber = (num?: number) => {
    if (!num) return '0';
    return new Intl.NumberFormat('es-MX').format(num);
  };

  const handleShare = async () => {
    if (!currentVideo) return;
    
    try {
      if (navigator.share) {
        await navigator.share({
          title: currentVideo.title,
          text: currentVideo.description,
          url: window.location.href
        });
        setShareMessage('¡Enlace compartido!');
      } else {
        await navigator.clipboard.writeText(window.location.href);
        setShareMessage('¡Enlace copiado al portapapeles!');
      }
      
      setTimeout(() => setShareMessage(null), 3000);
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white">
        <Navigation />
        <div className="container mx-auto px-6 py-24 flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-500 mx-auto mb-4"></div>
            <p className="text-xl text-gray-400">Cargando videos...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white">
        <Navigation />
        <div className="container mx-auto px-6 py-24">
          <div className="max-w-2xl mx-auto">
            <div className="bg-red-500/10 border border-red-500/30 rounded-lg p-6 text-red-400 flex items-center gap-4">
              <AlertCircle className="w-6 h-6 flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Error al cargar los videos</h3>
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!currentVideo) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white">
        <Navigation />
        <div className="container mx-auto px-6 py-24">
          <div className="max-w-2xl mx-auto text-center">
            <VideoIcon className="w-16 h-16 text-red-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-2">No hay videos disponibles</h2>
            <p className="text-gray-400">
              No se encontraron videos para mostrar en este momento.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white">
      <Navigation />
      
      <div className="container mx-auto px-6 py-24">
        <div className="max-w-6xl mx-auto">
          <div className="space-y-8">
            {/* Video Player */}
            <div className="aspect-video w-full bg-gray-800/30 rounded-2xl overflow-hidden backdrop-blur border border-red-500/20 shadow-xl">
              <iframe
                src={`https://www.youtube.com/embed/${currentVideo.youtube_id}?rel=0&modestbranding=1&autoplay=1`}
                title={currentVideo.title}
                className="w-full h-full"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            {/* Video Info */}
            <div className="space-y-6 bg-gray-800/30 rounded-2xl p-8 backdrop-blur border border-red-500/20 shadow-lg">
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                {currentVideo.title}
              </h1>
              
              <div className="flex flex-wrap gap-6 text-sm text-gray-400">
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4 text-red-500" />
                  <span>{formatDate(currentVideo.published_at)}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Eye className="w-4 h-4 text-red-500" />
                  <span>{formatNumber(currentVideo.view_count)} visualizaciones</span>
                </div>
                <div className="flex items-center gap-2">
                  <ThumbsUp className="w-4 h-4 text-red-500" />
                  <span>{formatNumber(currentVideo.like_count)} me gusta</span>
                </div>
                <div className="relative">
                  <button 
                    onClick={handleShare}
                    className="flex items-center gap-2 hover:text-white transition-colors group"
                  >
                    <Share2 className="w-4 h-4 text-red-500 group-hover:scale-110 transition-transform" />
                    <span>Compartir</span>
                  </button>
                  {shareMessage && (
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 whitespace-nowrap bg-green-500 text-white px-3 py-1 rounded-lg text-sm animate-fade-in-out">
                      {shareMessage}
                    </div>
                  )}
                </div>
                <a 
                  href={`https://www.youtube.com/watch?v=${currentVideo.youtube_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 hover:text-white transition-colors group"
                >
                  <ExternalLink className="w-4 h-4 text-red-500 group-hover:scale-110 transition-transform" />
                  <span>Ver en YouTube</span>
                </a>
              </div>

              <div className="border-t border-red-500/10 pt-6">
                <p className="text-gray-300 whitespace-pre-line leading-relaxed">
                  {currentVideo.description}
                </p>
              </div>
            </div>

            {/* Video List */}
            <div className="mt-12">
              <h2 className="text-2xl font-bold mb-8 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                Más Videos
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {videos.map((video) => (
                  <button
                    key={video.id}
                    onClick={() => handleVideoSelect(video)}
                    className={`group text-left ${
                      video.id === currentVideo.id
                        ? 'bg-red-500/20 border-red-500'
                        : 'bg-gray-800/30 hover:bg-gray-800/50 border-red-500/20'
                    } rounded-xl overflow-hidden border backdrop-blur transition-all duration-300 shadow-lg hover:shadow-xl hover:-translate-y-1`}
                  >
                    {/* Thumbnail */}
                    <div className="relative aspect-video">
                      {video.thumbnail_url ? (
                        <img
                          src={video.thumbnail_url}
                          alt={video.title}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="w-full h-full bg-gray-900 flex items-center justify-center">
                          <Play className="w-12 h-12 text-gray-600" />
                        </div>
                      )}
                      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <Play className="w-12 h-12 text-white transform scale-75 group-hover:scale-100 transition-transform" />
                      </div>
                    </div>
                    
                    {/* Info */}
                    <div className="p-4">
                      <h3 className="font-semibold line-clamp-2 mb-2 group-hover:text-red-400 transition-colors">
                        {video.title}
                      </h3>
                      <div className="flex items-center gap-4 text-sm text-gray-400">
                        <div className="flex items-center gap-1">
                          <Eye className="w-3 h-3 text-red-500" />
                          <span>{formatNumber(video.view_count)}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <Clock className="w-3 h-3 text-red-500" />
                          <span>{formatDate(video.published_at)}</span>
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Sitemap />
    </div>
  );
}

export default Videos;