import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup
} from 'react-simple-maps';
import { Tooltip } from 'react-tooltip';
import { MapPin, Globe2 } from 'lucide-react';

const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

interface City {
  id: string;
  name: string;
  coordinates: [number, number];
  country: string;
  details?: string;
  lastEvent?: string;
  nextEvent?: string;
  totalEvents?: number;
}

const cities: City[] = [
  // China
  { 
    id: "beijing", 
    name: "Beijing", 
    coordinates: [116.4074, 39.9042], 
    country: "China",
    details: "Conferencias sobre liderazgo y desarrollo personal",
    lastEvent: "2024-01-15",
    nextEvent: "2025-03-20",
    totalEvents: 5
  },
  { 
    id: "guangzhou", 
    name: "Guangzhou (Cantón)", 
    coordinates: [113.2644, 23.1291], 
    country: "China",
    details: "Seminarios de transformación empresarial",
    lastEvent: "2024-02-10",
    totalEvents: 3
  },
  { 
    id: "hong-kong", 
    name: "Hong Kong", 
    coordinates: [114.1694, 22.3193], 
    country: "China",
    details: "Eventos de desarrollo personal y liderazgo",
    lastEvent: "2024-03-01",
    nextEvent: "2025-04-15",
    totalEvents: 4
  },

  // Bolivia
  { 
    id: "santa-cruz", 
    name: "Santa Cruz de la Sierra", 
    coordinates: [-63.1814, -17.7833], 
    country: "Bolivia",
    details: "Conferencias magistrales y eventos de transformación",
    lastEvent: "2024-02-20",
    nextEvent: "2025-01-15",
    totalEvents: 8
  },
  { 
    id: "la-paz", 
    name: "La Paz", 
    coordinates: [-68.1193, -16.4897], 
    country: "Bolivia",
    details: "Seminarios de desarrollo personal y liderazgo",
    lastEvent: "2024-03-10",
    nextEvent: "2024-12-05",
    totalEvents: 6
  },
  { 
    id: "cochabamba", 
    name: "Cochabamba", 
    coordinates: [-66.1568, -17.3895], 
    country: "Bolivia",
    details: "Conferencias sobre desarrollo personal y transformación",
    lastEvent: "2024-03-15",
    nextEvent: "2024-11-20",
    totalEvents: 5
  },

  // México - Ciudades Principales
  { 
    id: "cdmx", 
    name: "Ciudad de México", 
    coordinates: [-99.1332, 19.4326], 
    country: "México", 
    details: "Conferencia magistral 'Es posible lo imposible'",
    lastEvent: "2024-12-15",
    nextEvent: "2025-04-15",
    totalEvents: 25
  },
  { 
    id: "monterrey", 
    name: "Monterrey", 
    coordinates: [-100.3161, 25.6866], 
    country: "México", 
    details: "Seminario de liderazgo empresarial",
    lastEvent: "2024-11-20",
    nextEvent: "2025-05-01",
    totalEvents: 18
  },
  { id: "guadalajara", name: "Guadalajara", coordinates: [-103.3496, 20.6597], country: "México" },
  { id: "chetumal", name: "Chetumal", coordinates: [-88.2998, 18.5013], country: "México" },
  
  // México - Chihuahua y alrededores
  { id: "juarez", name: "Ciudad Juárez", coordinates: [-106.4245, 31.6904], country: "México", details: "Múltiples conferencias sobre desarrollo personal" },
  { id: "chihuahua", name: "Chihuahua", coordinates: [-106.0691, 28.6353], country: "México", details: "Ciudad natal y sede de numerosos eventos" },
  { id: "parral", name: "Parral", coordinates: [-105.6667, 26.9333], country: "México" },
  { id: "delicias", name: "Delicias", coordinates: [-105.4700, 28.1900], country: "México" },
  { id: "camargo", name: "Camargo", coordinates: [-105.1700, 27.6800], country: "México" },
  { id: "cuauhtemoc", name: "Cuauhtémoc", coordinates: [-106.8667, 28.4083], country: "México" },
  { id: "creel", name: "Creel", coordinates: [-107.6333, 27.7500], country: "México" },
  
  // México - Norte
  { id: "torreon", name: "Torreón", coordinates: [-103.4068, 25.5428], country: "México" },
  { id: "piedras-negras", name: "Piedras Negras", coordinates: [-100.5231, 28.7007], country: "México" },
  
  // México - Noroeste
  { id: "guaymas", name: "Guaymas", coordinates: [-110.8989, 27.9241], country: "México" },
  { id: "los-mochis", name: "Los Mochis", coordinates: [-108.9973, 25.7904], country: "México" },
  
  // México - Pacífico
  { id: "tepic", name: "Tepic", coordinates: [-104.8946, 21.5044], country: "México" },
  { id: "tijuana", name: "Tijuana", coordinates: [-117.0382, 32.5149], country: "México" },
  { id: "rosarito", name: "Rosarito", coordinates: [-117.0617, 32.3614], country: "México" },
  { id: "tecate", name: "Tecate", coordinates: [-116.6203, 32.5667], country: "México" },
  { id: "ensenada", name: "Ensenada", coordinates: [-116.6058, 31.8667], country: "México" },
  
  // México - Sur
  { id: "villahermosa", name: "Villahermosa", coordinates: [-92.9475, 17.9892], country: "México" },
  
  // Estados Unidos - California
  { id: "los-angeles", name: "Los Angeles", coordinates: [-118.2437, 34.0522], country: "Estados Unidos", details: "Múltiples eventos con la comunidad latina" },
  { id: "san-francisco", name: "San Francisco", coordinates: [-122.4194, 37.7749], country: "Estados Unidos" },
  { id: "san-jose-ca", name: "San José", coordinates: [-121.8863, 37.3382], country: "Estados Unidos" },
  { id: "san-diego", name: "San Diego", coordinates: [-117.1611, 32.7157], country: "Estados Unidos" },
  
  // Estados Unidos - Texas
  { id: "el-paso", name: "El Paso", coordinates: [-106.4850, 31.7619], country: "Estados Unidos", details: "Conferencias en UTEP y eventos comunitarios" },
  { id: "dallas", name: "Dallas", coordinates: [-96.7970, 32.7767], country: "Estados Unidos" },
  { id: "san-antonio", name: "San Antonio", coordinates: [-98.4936, 29.4241], country: "Estados Unidos" },
  
  // Estados Unidos - Otros Estados
  { id: "phoenix", name: "Phoenix", coordinates: [-112.0740, 33.4484], country: "Estados Unidos" },
  { id: "atlanta", name: "Atlanta", coordinates: [-84.3880, 33.7490], country: "Estados Unidos" },
  { id: "minneapolis", name: "Minneapolis", coordinates: [-93.2650, 44.9778], country: "Estados Unidos" },
  { id: "chicago", name: "Chicago", coordinates: [-87.6298, 41.8781], country: "Estados Unidos" },
  { id: "new-york", name: "New York", coordinates: [-74.0060, 40.7128], country: "Estados Unidos", details: "Conferencias en el corazón de Manhattan" },
  { id: "las-vegas", name: "Las Vegas", coordinates: [-115.1398, 36.1699], country: "Estados Unidos" },
  { id: "reno", name: "Reno", coordinates: [-119.8138, 39.5296], country: "Estados Unidos" },
  { id: "miami", name: "Miami", coordinates: [-80.1918, 25.7617], country: "Estados Unidos", details: "Sede de múltiples certificaciones y eventos" },
  
  // Brasil
  { id: "sao-paulo", name: "São Paulo", coordinates: [-46.6333, -23.5505], country: "Brasil" },
  { id: "rio-de-janeiro", name: "Rio de Janeiro", coordinates: [-43.1729, -22.9068], country: "Brasil" },
  
  // Argentina
  { id: "buenos-aires", name: "Buenos Aires", coordinates: [-58.3816, -34.6037], country: "Argentina" },
  { id: "cordoba-ar", name: "Córdoba", coordinates: [-64.1811, -31.4201], country: "Argentina" },
  
  // Uruguay
  { id: "montevideo", name: "Montevideo", coordinates: [-56.1645, -34.9011], country: "Uruguay" },
  
  // Chile
  { id: "santiago", name: "Santiago", coordinates: [-70.6483, -33.4489], country: "Chile" },
  { id: "concepcion", name: "Concepción", coordinates: [-73.0498, -36.8270], country: "Chile" },
  
  // Colombia
  { id: "bogota", name: "Bogotá", coordinates: [-74.0721, 4.7110], country: "Colombia" },
  { id: "medellin", name: "Medellín", coordinates: [-75.5636, 6.2442], country: "Colombia" },
  { id: "cartagena", name: "Cartagena", coordinates: [-75.5144, 10.3910], country: "Colombia" },
  { id: "ibague", name: "Ibagué", coordinates: [-75.2322, 4.4389], country: "Colombia" },
  
  // Guatemala
  { id: "guatemala-city", name: "Ciudad de Guatemala", coordinates: [-90.5328, 14.6349], country: "Guatemala" },
  { id: "antigua", name: "La Antigua", coordinates: [-90.7344, 14.5583], country: "Guatemala" },
  
  // El Salvador
  { id: "san-salvador", name: "San Salvador", coordinates: [-89.2182, 13.6929], country: "El Salvador" },
  
  // España - Nuevas ciudades
  { id: "bilbao", name: "Bilbao", coordinates: [-2.9349, 43.2627], country: "España" },
  { id: "aranjuez", name: "Aranjuez", coordinates: [-3.6024, 40.0317], country: "España" },
  { id: "sevilla", name: "Sevilla", coordinates: [-5.9845, 37.3891], country: "España" },
  { id: "puerto-santa-maria", name: "El Puerto de Santa María", coordinates: [-6.2335, 36.5955], country: "España" },
  { id: "cadiz", name: "Cádiz", coordinates: [-6.2885, 36.5271], country: "España" },
  { id: "toledo", name: "Toledo", coordinates: [-4.0273, 39.8628], country: "España" },
  { id: "madrid", name: "Madrid", coordinates: [-3.7038, 40.4168], country: "España" },
  { id: "barcelona", name: "Barcelona", coordinates: [2.1734, 41.3851], country: "España" },
  
  // Reino Unido
  { id: "london", name: "Londres", coordinates: [-0.1276, 51.5074], country: "Reino Unido" },
  
  // Ecuador - Nuevas ciudades
  { id: "guayaquil", name: "Guayaquil", coordinates: [-79.9024, -2.1962], country: "Ecuador" },
  { id: "montanita", name: "Montañita", coordinates: [-80.7527, -1.8267], country: "Ecuador" },
  { id: "sabinas", name: "Sabinas", coordinates: [-78.5249, -0.3107], country: "Ecuador" },
  { id: "esmeraldas", name: "Esmeraldas", coordinates: [-79.6542, 0.9592], country: "Ecuador" },
  { id: "quito", name: "Quito", coordinates: [-78.4678, -0.1807], country: "Ecuador" },
  
  // Perú - Nuevas ciudades
  { id: "ayacucho", name: "Ayacucho", coordinates: [-74.2232, -13.1588], country: "Perú" },
  { id: "andahuaylas", name: "Andahuaylas", coordinates: [-73.3872, -13.6556], country: "Perú" },
  { id: "puno", name: "Puno", coordinates: [-70.0199, -15.8402], country: "Perú" },
  { id: "arequipa", name: "Arequipa", coordinates: [-71.5375, -16.4090], country: "Perú" },
  { id: "lima", name: "Lima", coordinates: [-77.0428, -12.0464], country: "Perú" },
  { id: "machu-picchu", name: "Machu Picchu", coordinates: [-72.5450, -13.1631], country: "Perú" },
  { id: "cusco", name: "Cusco", coordinates: [-71.9675, -13.5319], country: "Perú" },
  
  // Paraguay
  { id: "asuncion", name: "Asunción", coordinates: [-57.3333, -25.2867], country: "Paraguay" },
  
  // Otros países de Latinoamérica
  { id: "caracas", name: "Caracas", coordinates: [-66.9036, 10.4806], country: "Venezuela" },
  { id: "panama-city", name: "Ciudad de Panamá", coordinates: [-79.5167, 8.9833], country: "Panamá" },
  { id: "san-jose-cr", name: "San José", coordinates: [-84.0907, 9.9281], country: "Costa Rica" },
  { id: "havana", name: "La Habana", coordinates: [-82.3666, 23.1136], country: "Cuba" },
  
  // Europa
  { id: "paris", name: "París", coordinates: [2.3522, 48.8566], country: "Francia" },
  { id: "rome", name: "Roma", coordinates: [12.4964, 41.9028], country: "Italia" },
  
  // Asia
  { id: "tokyo", name: "Tokio", coordinates: [139.6917, 35.6895], country: "Japón" },
  { id: "singapore", name: "Singapur", coordinates: [103.8198, 1.3521], country: "Singapur" }
];

function WorldMap() {
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const handleMoveEnd = (position: { coordinates: [number, number]; zoom: number }) => {
    setPosition(position);
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('es-MX', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getTooltipContent = (city: City) => {
    let content = `<div class="font-semibold">${city.name}, ${city.country}</div>`;
    
    if (city.details) {
      content += `<div class="text-gray-300 mt-1">${city.details}</div>`;
    }
    
    if (city.totalEvents) {
      content += `<div class="text-red-400 mt-1">${city.totalEvents} eventos realizados</div>`;
    }
    
    if (city.lastEvent) {
      content += `<div class="text-gray-300 mt-1">Último evento: ${formatDate(city.lastEvent)}</div>`;
    }
    
    if (city.nextEvent) {
      content += `<div class="text-green-400 mt-1">Próximo evento: ${formatDate(city.nextEvent)}</div>`;
    }
    
    return content;
  };

  return (
    <section className="py-24 bg-black/50 relative">
      {/* Background World Map Image */}
      <div className="absolute inset-0 opacity-10">
        <img 
          src="https://images.unsplash.com/photo-1589519160732-57fc498494f8?auto=format&fit=crop&q=80"
          alt="World Map Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black" />
      </div>

      <div className="container mx-auto px-6 relative z-10">
        <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-white via-red-500 to-white bg-clip-text text-transparent">
          Presencia Global
        </h2>
        
        <div className="relative aspect-[2/1] w-full max-w-5xl mx-auto bg-gray-800/30 rounded-2xl overflow-hidden backdrop-blur">
          <ComposableMap
            projectionConfig={{
              scale: 147
            }}
          >
            <ZoomableGroup
              zoom={position.zoom}
              center={position.coordinates}
              onMoveEnd={handleMoveEnd}
            >
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#1f2937"
                      stroke="#374151"
                      strokeWidth={0.5}
                    />
                  ))
                }
              </Geographies>
              {cities.map((city) => (
                <Marker
                  key={city.id}
                  coordinates={city.coordinates}
                  data-tooltip-id="city-tooltip"
                  data-tooltip-html={getTooltipContent(city)}
                  onMouseEnter={() => setSelectedCity(city)}
                  onMouseLeave={() => setSelectedCity(null)}
                >
                  <circle
                    r={4}
                    fill={city.nextEvent ? "#22c55e" : "#ef4444"}
                    stroke="#fff"
                    strokeWidth={2}
                    className="animate-pulse cursor-pointer"
                  />
                </Marker>
              ))}
            </ZoomableGroup>
          </ComposableMap>
          
          <Tooltip
            id="city-tooltip"
            className="!bg-gray-900 !text-white !px-4 !py-2 !rounded-lg !shadow-xl !border !border-red-500/20"
            html={true}
          />
        </div>

        <div className="mt-8 text-center text-gray-400">
          <p>Más de 25 países impactados a través de conferencias y eventos</p>
          <div className="mt-4 flex items-center justify-center gap-8 text-sm">
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full bg-red-500"></div>
              <span>Eventos pasados</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full bg-green-500"></div>
              <span>Próximos eventos</span>
            </div>
            <div className="flex items-center gap-2">
              <MapPin className="w-4 h-4" />
              <span>Ciudades visitadas</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorldMap;