import React, { useState } from 'react';
import { Send, BookOpen, Star, Heart, X } from 'lucide-react';
import confetti from 'canvas-confetti';

function NewsletterFooter() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [showNameDialog, setShowNameDialog] = useState(false);
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [message, setMessage] = useState('');

  const triggerConfetti = () => {
    const duration = 3000;
    const end = Date.now() + duration;

    const colors = ['#ef4444', '#ffffff'];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });
      
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
  };

  const handleInitialSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return;
    setShowNameDialog(true);
  };

  const handleFinalSubmit = async () => {
    if (!email || !name) return;

    setStatus('loading');
    try {
      const response = await fetch("https://hook.us2.make.com/jevr2x5h8yciworwku51i5lfoen8nsq7", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
          date: new Date().toISOString()
        }),
      });

      if (!response.ok) throw new Error('Error en la suscripción');

      setStatus('success');
      setMessage('¡Gracias por suscribirte! Tu primer poema llegará mañana.');
      setEmail('');
      setName('');
      setShowNameDialog(false);
      triggerConfetti();
    } catch (error) {
      setStatus('error');
      setMessage('Hubo un error. Por favor, intenta nuevamente.');
    }
  };

  return (
    <section className="bg-gradient-to-b from-transparent to-black py-24">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto bg-gray-800/30 rounded-2xl p-8 backdrop-blur border border-red-500/20">
          <div className="text-center mb-8">
            <Star className="w-12 h-12 text-red-500 mx-auto mb-4" />
            <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
              Poemas Diarios de Omar Villalobos
            </h2>
            <p className="text-gray-300 text-lg">
              Recibe cada mañana un poema exclusivo para inspirar tu día
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            <div className="text-center">
              <BookOpen className="w-8 h-8 text-red-500 mx-auto mb-3" />
              <h3 className="font-semibold mb-2">Poemas Exclusivos</h3>
              <p className="text-gray-400 text-sm">
                Contenido original escrito por Omar
              </p>
            </div>
            <div className="text-center">
              <Heart className="w-8 h-8 text-red-500 mx-auto mb-3" />
              <h3 className="font-semibold mb-2">Inspiración Diaria</h3>
              <p className="text-gray-400 text-sm">
                Una dosis de motivación cada mañana
              </p>
            </div>
            <div className="text-center">
              <Star className="w-8 h-8 text-red-500 mx-auto mb-3" />
              <h3 className="font-semibold mb-2">Totalmente Gratis</h3>
              <p className="text-gray-400 text-sm">
                Un regalo para tu desarrollo personal
              </p>
            </div>
          </div>

          <form onSubmit={handleInitialSubmit} className="max-w-md mx-auto">
            <div className="flex flex-col sm:flex-row gap-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Tu correo electrónico"
                className="flex-1 bg-gray-900/50 rounded-lg border border-gray-700 px-4 py-2 focus:outline-none focus:border-red-500 w-full"
                required
              />
              <button
                type="submit"
                disabled={status === 'loading'}
                className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-lg font-medium transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed w-full sm:w-auto"
              >
                <Send className="w-4 h-4" />
                <span>{status === 'loading' ? 'Enviando...' : 'Suscribirme'}</span>
              </button>
            </div>

            {message && (
              <div className={`mt-4 text-center text-sm ${status === 'success' ? 'text-green-400' : 'text-red-400'}`}>
                {message}
              </div>
            )}

            <p className="text-gray-400 text-xs text-center mt-4">
              Al suscribirte, recibirás un poema diario de Omar Villalobos. Puedes cancelar en cualquier momento.
            </p>
          </form>
        </div>
      </div>

      {/* Name Collection Dialog */}
      {showNameDialog && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-gray-900 rounded-xl max-w-md w-full border border-red-500/20 p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold">Un último paso...</h3>
              <button
                onClick={() => setShowNameDialog(false)}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
                  ¿A qué nombre enviamos los poemas?
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full bg-gray-800 rounded-lg border border-gray-700 px-4 py-2 focus:outline-none focus:border-red-500"
                  placeholder="Tu nombre"
                  required
                />
              </div>

              <div className="bg-gray-800/50 rounded-lg p-4 text-sm text-gray-300">
                <p>
                  Al hacer clic en "Confirmar", aceptas recibir un poema diario de Omar Villalobos 
                  en tu correo electrónico ({email}). Podrás cancelar tu suscripción en cualquier momento.
                </p>
              </div>

              <div className="flex flex-col sm:flex-row justify-end gap-3 pt-4">
                <button
                  type="button"
                  onClick={() => setShowNameDialog(false)}
                  className="px-4 py-2 text-gray-400 hover:text-white transition-colors w-full sm:w-auto"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleFinalSubmit}
                  disabled={!name.trim() || status === 'loading'}
                  className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-lg font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2 w-full sm:w-auto"
                >
                  <Send className="w-4 h-4" />
                  <span>{status === 'loading' ? 'Enviando...' : 'Confirmar'}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default NewsletterFooter;