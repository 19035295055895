import React, { useState } from 'react';
import { BookOpen, Award, GraduationCap, Star, Brain, ChevronDown, ChevronUp, Heart } from 'lucide-react';

type FAQCategory = {
  title: string;
  icon: React.ReactNode;
  questions: Array<{
    question: string;
    answer: string;
  }>;
};

function FAQ() {
  const [openCategory, setOpenCategory] = useState<number | null>(null);
  const [openQuestion, setOpenQuestion] = useState<string | null>(null);

  const faqCategories: FAQCategory[] = [
    {
      title: "Bio",
      icon: <BookOpen className="w-6 h-6 text-red-500" />,
      questions: [
        {
          question: "¿Dónde nació Omar Villalobos?",
          answer: "Omar Villalobos nació en Chihuahua, México, en 1976."
        },
        {
          question: "¿Cuál fue su formación temprana?",
          answer: "Entre los 15 y 18 años, se formó en comunidades religiosas con la aspiración de convertirse en sacerdote, donde desarrolló sus habilidades de comunicación."
        },
        {
          question: "¿Cuál es su filosofía de vida?",
          answer: "Su filosofía se basa en la creencia de que todo es posible con la mentalidad y actitud correctas, combinando desarrollo personal con espiritualidad."
        }
      ]
    },
    {
      title: "Logros",
      icon: <Award className="w-6 h-6 text-red-500" />,
      questions: [
        {
          question: "¿Cuántos países ha visitado como conferencista?",
          answer: "Ha impartido conferencias en más de 25 países alrededor del mundo."
        },
        {
          question: "¿Cuál es su conferencia más exitosa?",
          answer: "'Es posible lo imposible' se ha convertido en una de sus conferencias más exitosas, presentada más de 1000 veces."
        },
        {
          question: "¿Cuántas personas ha impactado con su trabajo?",
          answer: "Ha impactado millones de vidas a través de sus conferencias, libros y programas de desarrollo personal."
        }
      ]
    },
    {
      title: "Estudios",
      icon: <GraduationCap className="w-6 h-6 text-red-500" />,
      questions: [
        {
          question: "¿Dónde realizó sus estudios?",
          answer: "Estudió en instituciones como la Universidad de Texas en El Paso (UTEP), el Instituto Teresiano de Psicología, el Tecnológico de Monterrey, y ha participado en programas en la Universidad de Harvard."
        },
        {
          question: "¿Qué certificaciones posee?",
          answer: "Tiene certificaciones con Richard Bandler en Miami y múltiples certificaciones en desarrollo humano y programación neurolingüística."
        },
        {
          question: "¿Cuál es su enfoque educativo?",
          answer: "Se considera un autodidacta apasionado y un eterno aprendiz, creyendo que el verdadero conocimiento se demuestra en la práctica y los resultados."
        }
      ]
    },
    {
      title: "Reconocimientos",
      icon: <Star className="w-6 h-6 text-red-500" />,
      questions: [
        {
          question: "¿Qué reconocimientos ha recibido por sus conferencias?",
          answer: "Ha sido reconocido como uno de los oradores más influyentes de Latinoamérica y ha participado en prestigiosos eventos TEDx."
        },
        {
          question: "¿Cuáles son sus principales contribuciones?",
          answer: "Es reconocido por revolucionar la industria del desarrollo personal en Latinoamérica y por su estilo único de comunicación."
        },
        {
          question: "¿Qué lo distingue de otros oradores?",
          answer: "Su estilo único que combina humor, profundidad emocional y enseñanzas prácticas lo ha convertido en un referente en la industria."
        }
      ]
    },
    {
      title: "Experiencia",
      icon: <Brain className="w-6 h-6 text-red-500" />,
      questions: [
        {
          question: "¿Cuántos años de experiencia tiene como orador?",
          answer: "Cuenta con más de 30 años de trayectoria comprobada como speaker profesional."
        },
        {
          question: "¿Qué tipo de eventos ha dirigido?",
          answer: "Ha dirigido conferencias magistrales, seminarios, talleres y eventos corporativos de alto nivel en múltiples países."
        },
        {
          question: "¿Cuál es su especialidad?",
          answer: "Se especializa en desarrollo humano, liderazgo, motivación y transformación personal, con un enfoque único en la psicología del éxito."
        }
      ]
    },
    {
      title: "Confesiones",
      icon: <Heart className="w-6 h-6 text-red-500" />,
      questions: [
        {
          question: "¿Es verdad que Omar Villalobos tiene seis hijos?",
          answer: "Sí."
        },
        {
          question: "¿Es verdad que los hijos de Omar Villalobos se llaman como genios y personajes famosos?",
          answer: "Sí."
        },
        {
          question: "¿Cómo se llaman los hijos de Omar Villalobos y qué significado tienen sus nombres?",
          answer: "- Juan Pablo: Por Juan, el discípulo más joven, y Pablo, el mejor orador.\n- Sofía: Por amor a la sabiduría.\n- Salomón: Por el rey Salomón.\n- Dalai: Por el Dalai Lama.\n- Dalí: Por Salvador Dalí.\n- Zeus: Porque se dio vida solo."
        },
        {
          question: "¿Cuál es el deporte favorito de Omar Villalobos?",
          answer: "La escalada en roca. ¡Uf! Me sudan las manos nomás de acordarme."
        }
      ]
    }
  ];

  return (
    <section className="py-24 bg-black/50">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-white via-red-500 to-white bg-clip-text text-transparent">
            Preguntas Frecuentes
          </h2>

          <div className="space-y-6">
            {faqCategories.map((category, categoryIndex) => (
              <div 
                key={category.title}
                className="bg-gray-800/30 rounded-2xl backdrop-blur overflow-hidden border border-red-500/20"
              >
                <button
                  onClick={() => setOpenCategory(openCategory === categoryIndex ? null : categoryIndex)}
                  className="w-full p-6 flex items-center justify-between hover:bg-white/5 transition-colors"
                >
                  <div className="flex items-center gap-4">
                    {category.icon}
                    <span className="text-xl font-semibold">{category.title}</span>
                  </div>
                  {openCategory === categoryIndex ? (
                    <ChevronUp className="w-6 h-6 text-red-500" />
                  ) : (
                    <ChevronDown className="w-6 h-6 text-red-500" />
                  )}
                </button>

                {openCategory === categoryIndex && (
                  <div className="px-6 pb-6">
                    <div className="space-y-4">
                      {category.questions.map((item) => (
                        <div key={item.question} className="border-b border-red-500/10 last:border-0">
                          <button
                            onClick={() => setOpenQuestion(openQuestion === item.question ? null : item.question)}
                            className="w-full py-4 flex items-center justify-between text-left hover:text-red-400 transition-colors"
                          >
                            <span className="text-lg">{item.question}</span>
                            {openQuestion === item.question ? (
                              <ChevronUp className="w-5 h-5 flex-shrink-0" />
                            ) : (
                              <ChevronDown className="w-5 h-5 flex-shrink-0" />
                            )}
                          </button>
                          {openQuestion === item.question && (
                            <p className="pb-4 text-gray-300 whitespace-pre-line">
                              {item.answer}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;