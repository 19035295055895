import React, { useState } from 'react';
import { Mic, Lock } from 'lucide-react';

interface FloatingMicProps {
  onClick: () => void;
}

function FloatingMic({ onClick }: FloatingMicProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [error, setError] = useState('');
  
  const generateDailyCode = () => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    return `OMV11!${day}`;
  };

  const handleAuthSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const correctCode = generateDailyCode();
    
    if (authCode === correctCode) {
      setShowAuthModal(false);
      setAuthCode('');
      setError('');
      onClick();
    } else {
      setError('Código incorrecto');
    }
  };

  const handleMicClick = () => {
    setShowAuthModal(true);
  };
  
  return (
    <>
      <div 
        className="fixed bottom-6 right-6 z-40"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <button
          onClick={handleMicClick}
          className="w-14 h-14 rounded-full bg-red-500 hover:bg-red-600 text-white flex items-center justify-center shadow-lg transition-all duration-300 hover:scale-110"
          aria-label="Activar reconocimiento de voz"
        >
          <Mic className="w-6 h-6" />
        </button>
        
        {isHovered && (
          <div className="absolute bottom-full right-0 mb-2 bg-gray-900 text-white text-sm py-1 px-3 rounded-lg shadow-lg whitespace-nowrap">
            Comando de voz
          </div>
        )}
      </div>

      {showAuthModal && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-gray-900 rounded-xl max-w-md w-full border border-red-500/20 p-6">
            <div className="flex items-center justify-center mb-6">
              <Lock className="w-12 h-12 text-red-500" />
            </div>
            
            <h3 className="text-xl font-semibold text-center mb-4">
              Autenticación Requerida
            </h3>
            
            <form onSubmit={handleAuthSubmit} className="space-y-4">
              <div>
                <label htmlFor="authCode" className="block text-sm font-medium text-gray-300 mb-1">
                  Código de Acceso
                </label>
                <input
                  type="text"
                  id="authCode"
                  value={authCode}
                  onChange={(e) => setAuthCode(e.target.value)}
                  className="w-full bg-gray-800 rounded-lg border border-gray-700 px-4 py-2 focus:outline-none focus:border-red-500 text-white"
                  placeholder="Ingresa el código del día"
                />
              </div>

              {error && (
                <div className="bg-red-500/10 border border-red-500/30 rounded-lg p-3 text-red-400 text-sm">
                  {error}
                </div>
              )}

              <div className="flex gap-3">
                <button
                  type="button"
                  onClick={() => setShowAuthModal(false)}
                  className="flex-1 bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="flex-1 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  Acceder
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default FloatingMic;