import { supabase } from './supabase';
import { Video, Playlist } from '../types';

interface ApiResponse<T> {
  success: boolean;
  data?: T;
  error?: string;
}

export async function getVideos(): Promise<ApiResponse<Video[]>> {
  try {
    const { data, error } = await supabase
      .from('videos')
      .select('*')
      .order('published_at', { ascending: false });

    if (error) throw error;

    return {
      success: true,
      data: data || [],
    };
  } catch (error) {
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to fetch videos',
    };
  }
}

export async function getVideoById(id: string): Promise<ApiResponse<Video>> {
  try {
    const { data, error } = await supabase
      .from('videos')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;

    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to fetch video',
    };
  }
}

export async function getDefaultPlaylist(): Promise<ApiResponse<Playlist>> {
  try {
    const { data, error } = await supabase
      .from('playlists')
      .select('*')
      .eq('youtube_id', 'PLaM11WLrfXP3aBd3g-BnMfs3gZJgxig_w')
      .single();

    if (error) throw error;

    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to fetch playlist',
    };
  }
}

export async function syncYouTubePlaylist(playlistId: string): Promise<ApiResponse<{ videoCount: number }>> {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/sync-youtube`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`,
        },
        body: JSON.stringify({ playlistId }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to sync playlist');
    }

    const data = await response.json();
    return {
      success: true,
      data: { videoCount: data.videoCount },
    };
  } catch (error) {
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to sync playlist',
    };
  }
}