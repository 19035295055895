import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Biography from './biography';
import Achievements from './achievements';
import Videos from './pages/Videos';
import FloatingMic from './components/FloatingMic';
import WhatsAppContact from './components/WhatsAppContact';
import { Mic, MicOff, Send, X, RefreshCw } from 'lucide-react';

interface SpeechRecognitionEvent extends Event {
  results: SpeechRecognitionResultList;
}

interface SpeechRecognitionResultList {
  item(index: number): SpeechRecognitionResult;
  length: number;
}

interface SpeechRecognitionResult {
  item(index: number): SpeechRecognitionAlternative;
  isFinal: boolean;
  length: number;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

function App() {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [showVoiceModal, setShowVoiceModal] = useState(false);
  const [recognition, setRecognition] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();

  const toggleVoiceRecognition = () => {
    if (showVoiceModal) {
      handleCloseModal();
    } else {
      startListening();
    }
  };

  const startListening = () => {
    setTranscript('');
    setError(null);
    setShowVoiceModal(true);
    
    try {
      if (!recognition) {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        
        if (SpeechRecognition) {
          const recognitionInstance = new SpeechRecognition();
          recognitionInstance.continuous = true;
          recognitionInstance.interimResults = true;
          recognitionInstance.lang = 'es-ES';
          
          recognitionInstance.onstart = () => {
            setIsListening(true);
          };
          
          recognitionInstance.onresult = (event: SpeechRecognitionEvent) => {
            const current = event.results[event.results.length - 1];
            const currentTranscript = current[0].transcript;
            setTranscript(currentTranscript);
          };
          
          recognitionInstance.onerror = (event: any) => {
            console.error('Speech recognition error', event.error);
            setError(`Error: ${event.error}`);
            stopListening();
          };
          
          recognitionInstance.onend = () => {
            setIsListening(false);
          };
          
          setRecognition(recognitionInstance);
          recognitionInstance.start();
        } else {
          setError('Tu navegador no soporta reconocimiento de voz');
        }
      } else {
        recognition.start();
      }
    } catch (err) {
      console.error('Error starting recognition:', err);
      setError('Error al iniciar el reconocimiento de voz');
    }
  };

  const stopListening = () => {
    if (recognition) {
      recognition.stop();
    }
    setIsListening(false);
  };

  const resetRecognition = () => {
    stopListening();
    setTranscript('');
    setTimeout(() => {
      startListening();
    }, 300);
  };

  const handleCloseModal = () => {
    stopListening();
    setShowVoiceModal(false);
  };

  const processCommand = async () => {
    if (!transcript.trim()) {
      setError('No hay texto para procesar');
      return;
    }

    setIsSending(true);
    
    try {
      const webhookUrl = "https://hook.us2.make.com/zk67juh8b7irvkbnoo9v4c2eihvqnflo";
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          solicitud: transcript,
          origen: "comando_voz_app",
          url: window.location.href
        })
      });

      if (!response.ok) {
        throw new Error(`Error al enviar datos: ${response.status}`);
      }

      const command = transcript.toLowerCase().trim();
      
      handleCloseModal();
      
      if (command.includes('inicio') || command.includes('home') || command.includes('principal')) {
        navigate('/');
      } else if (command.includes('biografía') || command.includes('biografia') || command.includes('quien es')) {
        navigate('/biography');
      } else if (command.includes('logros') || command.includes('reconocimientos') || command.includes('premios')) {
        navigate('/achievements');
      } else if (command.includes('videos') || command.includes('video')) {
        navigate('/videos');
      } else {
        setError('No se reconoció el comando. Intenta decir "inicio", "biografía", "logros" o "videos"');
        setTimeout(() => setError(null), 3000);
      }
    } catch (err) {
      console.error('Error al procesar el comando:', err);
      setError('Error al procesar el comando. Por favor, intenta nuevamente.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/biography" element={<Biography />} />
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/videos/:id" element={<Videos />} />
      </Routes>
      
      <FloatingMic onClick={toggleVoiceRecognition} />
      <WhatsAppContact />
      
      {showVoiceModal && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-gray-900 rounded-xl max-w-md w-full border border-red-500/20 overflow-hidden">
            <div className="p-4 flex items-center justify-between border-b border-red-500/20">
              <h3 className="text-xl font-semibold bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                Reconocimiento de voz
              </h3>
              <button
                onClick={handleCloseModal}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            
            <div className="p-6 space-y-4">
              <div className="flex justify-center">
                {isListening ? (
                  <div className="w-20 h-20 rounded-full bg-red-500/20 flex items-center justify-center animate-pulse">
                    <Mic className="w-10 h-10 text-red-500" />
                  </div>
                ) : (
                  <div className="w-20 h-20 rounded-full bg-gray-800 flex items-center justify-center">
                    <MicOff className="w-10 h-10 text-gray-500" />
                  </div>
                )}
              </div>
              
              <div className="text-center">
                <p className="text-sm text-gray-400 mb-2">
                  {isListening ? 'Escuchando...' : 'Presiona el botón para hablar'}
                </p>
                <div className="bg-gray-800 rounded-lg p-4 min-h-[60px] flex items-center justify-center relative">
                  {transcript ? (
                    <p className="text-white">{transcript}</p>
                  ) : (
                    <p className="text-gray-500 italic">
                      {isListening ? 'Di algo como "ir a inicio" o "abrir biografía"' : 'Presiona el botón para comenzar'}
                    </p>
                  )}
                  
                  {transcript && (
                    <button 
                      onClick={resetRecognition}
                      className="absolute right-2 top-2 text-gray-400 hover:text-white p-1 rounded-full hover:bg-gray-700 transition-colors"
                      title="Reiniciar grabación"
                    >
                      <RefreshCw className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </div>
              
              {error && (
                <div className="bg-red-500/10 border border-red-500/30 rounded-lg p-3 text-red-400 text-sm">
                  {error}
                </div>
              )}
              
              <div className="flex justify-center gap-4">
                <button
                  onClick={isListening ? stopListening : startListening}
                  className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                    isListening 
                      ? 'bg-red-500 hover:bg-red-600 text-white' 
                      : 'bg-gray-700 hover:bg-gray-600 text-white'
                  } transition-colors`}
                  disabled={isSending}
                >
                  {isListening ? (
                    <>
                      <MicOff className="w-4 h-4" />
                      <span>Detener</span>
                    </>
                  ) : (
                    <>
                      <Mic className="w-4 h-4" />
                      <span>Hablar</span>
                    </>
                  )}
                </button>
                
                {transcript && (
                  <>
                    <button
                      onClick={resetRecognition}
                      className="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-lg flex items-center gap-2 transition-colors"
                      disabled={isSending}
                    >
                      <RefreshCw className="w-4 h-4" />
                      <span>Reiniciar</span>
                    </button>
                    
                    <button
                      onClick={processCommand}
                      className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg flex items-center gap-2 transition-colors"
                      disabled={isSending}
                    >
                      <Send className="w-4 h-4" />
                      <span>{isSending ? 'Enviando...' : 'Ejecutar'}</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;