import React from 'react';
import { Award, Mic2, Globe2, Youtube, Star, ArrowRight, Users, Trophy, Calendar, BookOpen, Brain, Heart, Medal, Crown, Target, Rocket, Zap, CheckCircle2 } from 'lucide-react';
import Navigation from './Navigation';
import Sitemap from './components/Sitemap';

function Achievements() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white">
      <Navigation />
      
      {/* Hero Section */}
      <header className="relative h-[60vh] flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?auto=format&fit=crop&q=80"
            alt="Conference Stage" 
            className="w-full h-full object-cover opacity-25"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-80"></div>
        </div>
        <div className="relative z-10 container mx-auto px-6 text-center">
          <h1 className="text-6xl md:text-7xl font-extrabold mb-6 tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-white via-red-500 to-white">
            Logros y Reconocimientos
          </h1>
          <div className="flex items-center justify-center">
            <Award className="w-8 h-8 text-red-500 mr-3" />
            <p className="text-2xl font-light tracking-wider text-gray-300">
              Una Trayectoria de Excelencia
            </p>
          </div>
        </div>
      </header>

      {/* Main Achievements Grid */}
      <section className="py-24">
        <div className="container mx-auto px-6">
          <div className="max-w-6xl mx-auto">
            <div className="text-center mb-16">
              <Star className="w-16 h-16 text-red-500 mx-auto mb-6" />
              <p className="text-xl leading-relaxed text-gray-300">
                Omar Villalobos es una figura reconocida en el ámbito del desarrollo humano y la oratoria, 
                con una trayectoria marcada por hitos profesionales y reconocimientos internacionales.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Reconocimiento como líder influyente */}
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur border border-red-500/20 hover:border-red-500/40 transition-all duration-300 group">
                <Crown className="w-12 h-12 text-red-500 mb-6 group-hover:scale-110 transition-transform" />
                <h3 className="text-2xl font-bold mb-4">Líder Influyente</h3>
                <p className="text-gray-300 mb-4">
                  Considerado uno de los referentes más destacados en psicología práctica y desarrollo 
                  humano en Latinoamérica.
                </p>
                <ul className="space-y-2 text-gray-300">
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Más de medio millón de seguidores en redes sociales</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Más de tres mil eventos en vivo</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Referente en desarrollo personal</span>
                  </li>
                </ul>
              </div>

              {/* Doctorados Honoris Causa */}
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur border border-red-500/20 hover:border-red-500/40 transition-all duration-300 group">
                <Medal className="w-12 h-12 text-red-500 mb-6 group-hover:scale-110 transition-transform" />
                <h3 className="text-2xl font-bold mb-4">Doctorados Honoris Causa</h3>
                <p className="text-gray-300 mb-4">
                  Reconocimiento académico por su contribución al desarrollo humano y la educación.
                </p>
                <ul className="space-y-2 text-gray-300">
                  <li className="flex items-center">
                    <ArrowRight className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Universidad de Ecuador (2007)</span>
                  </li>
                  <li className="flex items-center">
                    <ArrowRight className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Universidad de Puerto Rico (2009)</span>
                  </li>
                  <li className="flex items-center">
                    <ArrowRight className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Universidad de México (2011)</span>
                  </li>
                </ul>
              </div>

              {/* Récord Guinness */}
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur border border-red-500/20 hover:border-red-500/40 transition-all duration-300 group">
                <Trophy className="w-12 h-12 text-red-500 mb-6 group-hover:scale-110 transition-transform" />
                <h3 className="text-2xl font-bold mb-4">Récord Guinness</h3>
                <p className="text-gray-300 mb-4">
                  Récord Guinness por el abrazo más grande del mundo, en Ayacucho, Perú.
                </p>
                <ul className="space-y-2 text-gray-300">
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Más de doce mil participantes</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Evento histórico en Ayacucho</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Impacto global simultáneo</span>
                  </li>
                </ul>
              </div>

              {/* Autor Bestseller */}
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur border border-red-500/20 hover:border-red-500/40 transition-all duration-300 group">
                <BookOpen className="w-12 h-12 text-red-500 mb-6 group-hover:scale-110 transition-transform" />
                <h3 className="text-2xl font-bold mb-4">Autor Bestseller</h3>
                <p className="text-gray-300 mb-4">
                  Obras literarias que han transformado miles de vidas.
                </p>
                <ul className="space-y-2 text-gray-300">
                  <li className="flex items-center">
                    <ArrowRight className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>"Ser Chingón Sin Ir a Harvard"</span>
                  </li>
                  <li className="flex items-center">
                    <ArrowRight className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>"Haz Posible lo Imposible"</span>
                  </li>
                  <li className="flex items-center">
                    <ArrowRight className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>"Genius: La Psicología de la Genialidad"</span>
                  </li>
                </ul>
              </div>

              {/* Conferencias Internacionales */}
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur border border-red-500/20 hover:border-red-500/40 transition-all duration-300 group">
                <Globe2 className="w-12 h-12 text-red-500 mb-6 group-hover:scale-110 transition-transform" />
                <h3 className="text-2xl font-bold mb-4">Presencia Internacional</h3>
                <p className="text-gray-300 mb-4">
                  Conferencias y seminarios en los principales escenarios del mundo.
                </p>
                <ul className="space-y-2 text-gray-300">
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Más de 25 países visitados</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Eventos en 4 continentes</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Audiencias multiculturales</span>
                  </li>
                </ul>
              </div>

              {/* Innovación en Desarrollo Personal */}
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur border border-red-500/20 hover:border-red-500/40 transition-all duration-300 group">
                <Rocket className="w-12 h-12 text-red-500 mb-6 group-hover:scale-110 transition-transform" />
                <h3 className="text-2xl font-bold mb-4">Innovación Educativa</h3>
                <p className="text-gray-300 mb-4">
                  Pionero en metodologías de desarrollo personal y profesional.
                </p>
                <ul className="space-y-2 text-gray-300">
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Programas educativos únicos</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Metodologías revolucionarias</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
                    <span>Tecnologías de vanguardia</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Impact Stats */}
      <section className="py-16 bg-black/50">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 max-w-4xl mx-auto">
            <div className="text-center group">
              <div className="text-5xl font-bold text-red-500 mb-2 group-hover:scale-110 transition-transform">3</div>
              <div className="text-xl text-gray-300">Doctorados Honoris Causa</div>
            </div>
            <div className="text-center group">
              <div className="text-5xl font-bold text-red-500 mb-2 group-hover:scale-110 transition-transform">1</div>
              <div className="text-xl text-gray-300">Récord Guinness</div>
            </div>
            <div className="text-center group">
              <div className="text-5xl font-bold text-red-500 mb-2 group-hover:scale-110 transition-transform">25+</div>
              <div className="text-xl text-gray-300">Países Impactados</div>
            </div>
            <div className="text-center group">
              <div className="text-5xl font-bold text-red-500 mb-2 group-hover:scale-110 transition-transform">1M+</div>
              <div className="text-xl text-gray-300">Vidas Transformadas</div>
            </div>
          </div>
        </div>
      </section>

      {/* Recent Recognition */}
      <section className="py-24">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16 bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
              Reconocimientos Recientes
            </h2>

            <div className="space-y-8">
              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur group hover:bg-gray-800/40 transition-all duration-300">
                <div className="flex items-center mb-6">
                  <Target className="w-8 h-8 text-red-500 mr-4 group-hover:scale-110 transition-transform" />
                  <h3 className="text-2xl font-bold">Foro Inmobiliario AMPI Mazatlán 2024</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Reconocido como el orador principal en uno de los eventos más importantes del sector 
                  inmobiliario en México, compartiendo el escenario con líderes de la industria.
                </p>
              </div>

              <div className="bg-gray-800/30 rounded-2xl p-8 backdrop-blur group hover:bg-gray-800/40 transition-all duration-300">
                <div className="flex items-center mb-6">
                  <Zap className="w-8 h-8 text-red-500 mr-4 group-hover:scale-110 transition-transform" />
                  <h3 className="text-2xl font-bold">Premio a la Excelencia en Oratoria 2024</h3>
                </div>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Galardonado por su extraordinaria contribución al desarrollo del arte de la oratoria 
                  y su impacto en la transformación de vidas a través de la comunicación efectiva.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black py-8">
        <div className="container mx-auto px-6 text-center text-gray-400">
          <p className="text-lg">&copy; {new Date().getFullYear()} Omar Villalobos. Todos los derechos reservados.</p>
        </div>
      </footer>

      {/* Sitemap */}
      <Sitemap />
    </div>
  );
}

export default Achievements;