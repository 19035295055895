import React from 'react';
import { Home, BookOpen, Award, Mail, Clock, Apple as WhatsApp } from 'lucide-react';
import { Link } from 'react-router-dom';
import NewsletterFooter from './NewsletterFooter';

function Footer() {
  const currentYear = new Date().getFullYear();
  
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent('Pasé por omarvillalobos.com, y me interesó contactar a OMV');
    window.open(`https://wa.me/523324166849?text=${message}`, '_blank');
  };

  return (
    <>
      <NewsletterFooter />
      
      <footer className="bg-black py-12 border-t border-red-500/20">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            {/* Columna 1: Logo y descripción */}
            <div className="space-y-4">
              <h3 className="text-xl font-bold bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent">
                Omar Villalobos
              </h3>
              <p className="text-gray-400 text-sm">
                Maestro de la transformación personal y profesional con más de 30 años de experiencia inspirando a millones de personas.
              </p>
            </div>
            
            {/* Columna 2: Enlaces rápidos */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Enlaces rápidos</h4>
              <ul className="space-y-2">
                <li>
                  <Link to="/" className="text-gray-400 hover:text-white transition-colors flex items-center gap-2">
                    <Home className="w-4 h-4" />
                    <span>Inicio</span>
                  </Link>
                </li>
                <li>
                  <Link to="/biography" className="text-gray-400 hover:text-white transition-colors flex items-center gap-2">
                    <BookOpen className="w-4 h-4" />
                    <span>Biografía</span>
                  </Link>
                </li>
                <li>
                  <Link to="/achievements" className="text-gray-400 hover:text-white transition-colors flex items-center gap-2">
                    <Award className="w-4 h-4" />
                    <span>Logros</span>
                  </Link>
                </li>
                <li>
                  <a 
                    href="https://www.omvbooks.com/omv" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-gray-400 hover:text-white transition-colors flex items-center gap-2"
                  >
                    <BookOpen className="w-4 h-4" />
                    <span>Libros</span>
                  </a>
                </li>
              </ul>
            </div>
            
            {/* Columna 3: Contacto */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Contacto</h4>
              <ul className="space-y-4">
                <li>
                  <a 
                    href="mailto:direccion@omarvillalobos.com" 
                    className="text-gray-400 hover:text-white transition-colors flex items-center gap-2"
                  >
                    <Mail className="w-4 h-4" />
                    <span>direccion@omarvillalobos.com</span>
                  </a>
                </li>
                <li>
                  <button
                    onClick={handleWhatsAppClick}
                    className="text-gray-400 hover:text-white transition-colors flex items-center gap-2"
                  >
                    <WhatsApp className="w-4 h-4" />
                    <span>Contactar por WhatsApp</span>
                  </button>
                </li>
                <li className="flex items-center gap-2 text-gray-400">
                  <Clock className="w-4 h-4" />
                  <span>Disponible 24/7</span>
                </li>
              </ul>
            </div>
          </div>
          
          {/* Copyright */}
          <div className="pt-8 border-t border-gray-800 text-center text-gray-500 text-sm">
            <p>&copy; {currentYear} Omar Villalobos. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;