import React from 'react';
import { Home, Award, BookOpen, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import Footer from './Footer';

function Sitemap() {
  const pages = [
    {
      title: 'Inicio',
      path: '/',
      icon: <Home className="w-4 h-4" />,
      description: 'Conoce a Omar Villalobos'
    },
    {
      title: 'Biografía',
      path: '/biography',
      icon: <BookOpen className="w-4 h-4" />,
      description: 'Historia y trayectoria personal'
    },
    {
      title: 'Logros',
      path: '/achievements',
      icon: <Award className="w-4 h-4" />,
      description: 'Reconocimientos y éxitos'
    },
    {
      title: 'Contacto',
      path: '/contact',
      icon: <Phone className="w-4 h-4" />,
      description: 'Conecta con Omar'
    }
  ];

  return (
    <>
      <Footer />
    </>
  );
}

export default Sitemap;